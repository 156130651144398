import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

import ResponsiveImage from "@/components/ResponsiveImage";

import styles from "./HighlightsWithPhotoBackground.module.scss";

const HighlightsWithPhotoBackground = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Up" || eventData.dir === "Down") {
        return;
      }
      if (eventData.dir === "Left") {
        if (currentIndex + 1 < data?.highlights.length) {
          setCurrentIndex(currentIndex + 1);
        }
      } else {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
      }
    },
    trackMouse: true,
  });

  const handleHighlightChange = (index) => setCurrentIndex(index);

  return (
    <section
      className={styles.container}
      data-is="HighlightsWithPhotoBackground"
    >
      <ul
        className={`${styles.highlights}`}
        data-current-index={currentIndex}
        {...swipeHandlers}
      >
        {data?.highlights?.map((highlight, index) => {
          const {
            fields: {
              ctaShortText,
              ctaText,
              ctaUrl,
              title,
              photo,
              mobilePhoto,
              text,
            },
          } = highlight;

          return (
            <li
              aria-current={index === currentIndex}
              className={`${styles.highlight}`}
              key={highlight.sys.id}
            >
              <div
                className={`${styles.highlightInfo} ${
                  index === currentIndex ? `${styles.active}` : ""
                }`}
                onClick={() => handleHighlightChange(index)}
                onMouseEnter={() => handleHighlightChange(index)}
              >
                <h3 className={styles.highlightTitle}>{title}</h3>

                <p className={styles.highlightText}>{text}</p>

                {ctaText && ctaUrl && (
                  <a
                    className={styles.highlightLink}
                    href={ctaUrl}
                    title={ctaText}
                  >
                    {ctaText !== ctaShortText && (
                      <>
                        <span className={styles.ctaText}>{ctaText}</span>
                        <span className={styles.ctaShortText}>
                          {ctaShortText}
                        </span>
                      </>
                    )}
                    {ctaText === ctaShortText && ctaText}
                  </a>
                )}
              </div>

              <ResponsiveImage
                altText={mobilePhoto?.fields.title}
                className={styles.photo}
                defaultImage={mobilePhoto}
                lgImage={photo}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

HighlightsWithPhotoBackground.propTypes = {
  data: PropTypes.object,
};

export default HighlightsWithPhotoBackground;
